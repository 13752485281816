import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { Chevron } from '../assets/Chevron'
import { elexity } from '../utilities/AdvancedGraphHelper'
import icon_email from '../assets/icon_email.svg'
import icon_phone from '../assets/icon_phone.svg'
import withRouter from '../utilities/WithRouter'
import authentication from '../utilities/Authentication'
import configuration from '../config'
import AuthenticatedComponent from '../utilities/AuthenticatedComponent'

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentGX,
  updateCurrentCampusData,
} from '../actions'

const Sidebar = ({
  campuses,
  currentCampusId,
  currentBuildingId,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentGX,
  router,
  currentUserData,
  showMobile,
  updateCurrentCampusData,
  activeTab,
}) => {
  const [shouldShowSidebar, setShouldShowSidebar] = useState(false)
  const [buildingData, setBuildingData] = useState()
  const authenticatedComponent = new AuthenticatedComponent
  const getHeader = authenticatedComponent.generateAuthenticatedHeader()
  const pageParam = window.location.pathname.split('/')[1]

  const navigateWithValidation = (newPath) => {
    if (window.location.pathname !== newPath) {
      router.navigate(newPath)
    }
  }

  const getNavList = () => {
    fetch (configuration['backend_host'] + '/ahiapi/buildings2', 
    {
      headers: getHeader
    })

    .then(response => {
      if (!response.ok) {
        throw new Error('Not Accessible')
      }

      return response.json()
    })

    .then(data => {
      setBuildingData(data)
      if (showMobile && pageParam !== 'settings' && pageParam !== 'resources') {
        if (window.location.pathname === '/' || window.location.pathname === '/home/' || pageParam !== 'home') {
          navigateWithValidation('/home/' + currentCampusId + '/' + currentBuildingId)
        } else if (window.location.pathname !== null && pageParam === 'home') {
          navigateWithValidation(window.location.pathname)
        }
      }
    })
  }

  const handleResize = () => {
    if (window.innerWidth < 768 || window.screen.width < 768) {
      getNavList()
    } else {
      if (window.location.pathname === '/') {
        navigateWithValidation(activeTab + '/' + currentCampusId)
      } else if (window.location.pathname.split('/')[1] === 'home') {
        navigateWithValidation('/comfort/' + currentCampusId)
      } else {
        navigateWithValidation(window.location.pathname)
      }
    }
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)
    
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [activeTab])

  useEffect(() => {
    setShouldShowSidebar(false)
  }, [pageParam === 'settings', pageParam === 'resources'])

  const onOptionChange = (newCampusId, newBuildingId) => {
    setCurrentCampus(newCampusId)
    setCurrentBuilding(newBuildingId)

    if (currentCampusId !== newCampusId) {
      fetch(
        configuration['backend_host'] 
          + '/ahiapi/' + newCampusId
          + '/campus/comfort',
        {
          headers: getHeader
        }
      )

      .then(response => response.json())

      .then(data => {
        updateCurrentCampusData(data)
      })

      .catch(error => console.error(error))
    }

    if (newBuildingId !== null) {
      if (showMobile) {
        navigateWithValidation('/home/' + newCampusId + '/' + newBuildingId)
      } else {
        navigateWithValidation('/comfort/' + newCampusId + '/' + newBuildingId)
      }
    } else {
      if (showMobile) {
        navigateWithValidation('/home/' + newCampusId)
      } else {
        navigateWithValidation(`/${activeTab}/` + newCampusId)
        setCurrentGX(null)
      }
    }

    elexity.admin.monitor.graphs.halt()
    setShouldShowSidebar(false)
  }

  const handleBuildingChange = (e) => {
    let keepCampus
    campuses.filter((campus) => {
      campus.buildings.map((building) => {
        if (building.buildingEntityKey === e.target.value) {
          keepCampus = campus
        }
      })
    })

    if (keepCampus) {
      if (onOptionChange) {
        onOptionChange(keepCampus.campusEntityKey, e.target.value)
      }
    }
  }

  const handleCampusChange = (e) => {
    onOptionChange(e.target.value, null)
  }

  const handleShowSidebar = () => {
    setShouldShowSidebar(!shouldShowSidebar)
  }

  const renderProfileInfo = () => {
    if (currentUserData) {
      return (
        <>
          <div className="user-container">          
            <div className="name-email-container">
              <div className="sidebar-profile-name">
                {currentUserData['first_name']} {currentUserData['last_name']}
              </div>
              <div className="sidebar-profile-email">
                {currentUserData['email']}
              </div>
            </div>
          </div>
        </>
      )
    }
    return false
  }

  return (
    <>
      <div className={shouldShowSidebar ? 'sidebar' : 'sidebar hide'}>

        <div className={shouldShowSidebar ? "show-navbar show" : "show-navbar"} onClick={() => handleShowSidebar()}><Chevron /></div>

        <div className="sidebar-main-container">
          <div className="sidebar-header">
            {renderProfileInfo()}

            <div className="sidebar-header-search">
              {showMobile ? (
                <select 
                  className='building-menu'
                  onChange={handleBuildingChange}
                  value={currentBuildingId}
                >
                  {buildingData && buildingData.map((building) => (
                    <option 
                      key={`side-bar-${building.buildingEntityKey}`}
                      value={building.buildingEntityKey}
                    >
                      {building.shortBuildingDisplayName}
                    </option>
                  ))}
                </select>
              ) 
              : 
              (
                <select 
                  className='campus-menu'
                  onChange={handleCampusChange}
                  value={(currentCampusId === null) ? '' : currentCampusId}
                >
                  <option value="" disabled>Select a Campus</option>
                  {campuses && campuses.map((campus) => (
                    <option 
                      key={`side-bar-${campus.campusEntityKey}`}
                      value={campus.campusEntityKey}
                    >
                      {campus.shortCampusDisplayName}
                    </option>
                  ))}
                </select>
                )}

            </div>
          </div>
          
          <div className="sidebar-footer">
            <div className="sidebar-support">
                <span>Support</span>

                <div className="support-details">
                  <p>Hours: 9am - 5pm (PST)</p>
                  <p>Callback within: 
                    <br/>
                    1 hour (Weekdays)
                    <br/>
                    4 hours (Weekends)
                  </p>
                </div>

                <a href='mailto:support@elexity.io' className="email-us">
                  <img
                    src={icon_email}
                    alt='email icon'
                    width="14"
                    height="14"
                  />
                  support@elexity.io
                </a>

                <a href='tel:5102259329' className="call-us">
                  <img
                    src={icon_phone}
                    alt='phone icon'
                    width="14"
                    height="14"
                  />
                  510-225-9329
                </a>

                <NavLink
                  className="sidebar-resources"
                    to={'/resources'}
                  >
                    Tutorial Videos
                </NavLink>
            </div>

            <div className="sidebar-profile">
              <NavLink
              className="sidebar-settings"
                to={'/settings'}
              >
                User Settings
              </NavLink>

              <a
                className="sidebar-logout"
                onClick={authentication.logout}
                href={authentication.logoutLink}
              >
                Log Out
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={shouldShowSidebar ? "sidebar-overlay show" : "sidebar-overlay"}></div>
    </>
  )
  
}
const mapStateToProps = (state) => {
  return {
    campuses: state.campuses,
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))