import './css/index.css';
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Routes, Route, Router } from 'react-router-dom'

import Sidebar from './components/Sidebar'
import PageHeader from './components/PageHeader'
import Homepage from './pages/Homepage'
import Energy from './pages/Energy'
import Schedules from './pages/Schedules'
import Events from './pages/Events'
import Settings from './pages/Settings'
import Resources from './pages/Resources'
import LobbyKiosk from './pages/LobbyKiosk'
import Loading from './components/Loading'

import {
  saveCampuses,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  setCurrentGXData,
  updateCurrentCampusData,
  setUserData
} from './actions'
import authentication from './utilities/Authentication'
import AuthenticatedComponent from './utilities/AuthenticatedComponent'
import configuration from './config'
import elexity_main_logo from './assets/elexity_main_logo.png'

const App = ({
  saveCampuses,
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  setCurrentGXData,
  updateCurrentCampusData,
  setUserData,
  currentCampusId,
  currentBuildingId,
  currentZoneId,
  currentGXId,
  currentCampusData,
  currentGXData
}) => {
  const hasSchedule = sessionStorage.scheduleId
  const hasFilter = sessionStorage.pieFilter !== undefined
  let pieFilter = hasFilter && JSON.parse(sessionStorage.pieFilter).name

  if (pieFilter) {
    pieFilter = pieFilter[0].toLowerCase() + pieFilter.slice(1)
    pieFilter = pieFilter.replace(/ /g, '')
  }

  let currentUrl = window.location.pathname
  let pageParam = window.location.pathname.split('/')[1]
  let campusParam = window.location.pathname.split('/')[2]
  let buildingParam = window.location.pathname.split('/')[3]
  let zoneParam = window.location.pathname.split('/')[4]
  let gxParam = window.location.pathname.split('/')[3]
  let newTabName = pageParam

  const [campusesLoaded, setCampusLoaded] = useState(false)
  const [activeTab, setActiveTab] = useState('comfort')
  const [showMobile, setShowMobile] = useState(window.innerWidth < 768 || window.screen.width < 768)
  const [showLoading, setShowLoading] = useState(false)
  const [selectedModal, setSelectedModal] = useState(null)
  const [currentScheduleId, setCurrentScheduleId] = useState(hasSchedule ? hasSchedule : null)
  const [activeFilter, setActiveFilter] = useState(hasFilter ? [pieFilter] : [])
  const [hasSingleGX, setHasSingleGX] = useState(false)

  const setParams = (newUrl) => {
    pageParam = newUrl.split('/')[1]
    campusParam = newUrl.split('/')[2]
    buildingParam = newUrl.split('/')[3]
    zoneParam = newUrl.split('/')[4]
    gxParam = newUrl.split('/')[3]
    newTabName = newUrl.split('/')[1] !== '' ? newUrl.split('/')[1].slice(1) : 'comfort'
  }
 
  const authenticatedComponent = new AuthenticatedComponent

  useEffect(() => {
    setShowMobile(window.innerWidth < 768 || window.screen.width < 768)
    handleResize()

    sessionStorage.removeItem('scheduleId')

    if (pageParam !== '' && newTabName !== '') {
      if (pageParam !== 'settings' && pageParam !== 'resources') {
        setActiveTab(newTabName)

        //if tab is comfort
        if (newTabName === 'comfort' || pageParam === 'home') {
          //if url has building, set current building
          if (buildingParam !== undefined) {
            setCurrentBuilding(buildingParam)
          }
          
          // if url has zone, set current zone
          if (zoneParam !== undefined) {
            setCurrentZone(zoneParam)
          }
        }

        //if tab is energy
        if (newTabName === 'energy') {
          // if url has gx, set current gx
          if (gxParam !== undefined) {
            setCurrentGX(gxParam)
          }
        }
      }
    }

    window.addEventListener('focus', validateAuthentication)
    window.addEventListener('resize', handleResize)
    window.addEventListener('popstate', onBackButtonEvent)
    
    return () => {
      window.removeEventListener('focus', validateAuthentication)
      window.removeEventListener('resize', handleResize)
      window.addEventListener('popstate', onBackButtonEvent)
    }
  }, [])

  const onBackButtonEvent = (e) => {
    e.preventDefault()
    let currentLocation = window.location.pathname
    
    window.location.replace(currentLocation)
  }

  const handleResize = () => {
    let newUrl

    if (window.innerWidth < 768 || window.screen.width < 768) {
      if (window.location.pathname.split('/')[1] !== 'home' && window.location.pathname.split('/')[1] !== '') {
        if (window.location.pathname.split('/')[1] !== 'settings' && window.location.pathname.split('/')[1] !== 'resources') {
          newUrl = window.location.pathname.replace(window.location.pathname.split('/')[1], 'home')
        } else {
          newUrl = window.location.pathname.replace('comfort', 'home')
        }

        if (newUrl !== window.location.pathname) {
          window.location.replace(newUrl)
        }
      }
    } else {
      if (window.location.pathname.split('/')[1] !== 'comfort') {
        if (currentBuildingId) {
          newUrl = `/comfort/${campusParam}`
        } else {
          newUrl = window.location.pathname.replace('home', 'comfort')
        }
        
        if (newUrl !== window.location.pathname) {
          window.location.replace(newUrl)
        }
      }
    }

    setShowMobile(window.innerWidth < 768 || window.screen.width < 768)
  }

  const updateCampusData = (currentCampusId) => {
    let comfortUrl
  
    if (currentCampusId) {
      comfortUrl = configuration['backend_host'] + '/ahiapi/' 
        + currentCampusId + '/campus/comfort'
    } else {
      comfortUrl = configuration['backend_host'] + '/ahiapi/campus/comfort'
    }

    fetch(
      comfortUrl, 
      {
        headers: authenticatedComponent.generateAuthenticatedHeader()
      }
    )
  
    .then(response => {
      if (!response.ok) {
        throw new Error('Not Accessible')
      }
      return response.json()
    })
    .then(data => {
      setData(data)
    })
    .catch(error => {
      console.error(error)
    })
  }

  const setData = (data) => {
    updateCurrentCampusData(data)
  }

  const updateGXData = (currentCampusId) => {
    if (currentCampusId) {
      fetch(
        configuration['backend_host'] 
            + '/ahiapi/' + currentCampusId
            + '/campus/energy', 
        {
          headers: authenticatedComponent.generateAuthenticatedHeader()
        }
      )
    
      .then(response => {
        if (!response.ok) {
          throw new Error('Not Accessible')
        }
        return response.json()
      })

      .then(data => {
        successGetEnergyData(data)
      })

      .catch(error => {
        console.error(error)
      })
    }
  }

  const fetchKioskData = () => {
    fetch(
      configuration['backend_host'] 
        + '/ahiapi/lobbyKiosk/' + gxParam
    )
    
    .then(response => {
      if (!response.ok) {
          throw new Error('Not Accessible')
      }
      return response.json()
    })

    .then(data => {
      setCurrentGXData(data)
    })

    .catch(error => {
      console.error(error)
    })
  }

  const successGetComfortData = (comfortData) => {
    updateCurrentCampusData(comfortData)
  }

  const successGetEnergyData = (energyData) => {
    setHasSingleGX(energyData.gridInterconnects.length === 1)
    setCurrentGXData(energyData)
  }

  const onMeterSelect = (meterId) => {
    if (currentCampusData && meterId !== currentGXId) {
      setCurrentGX(meterId)
    }
  }

  const onBuildingChange = (buildingId) => {
    if (currentBuildingId !== buildingId) {
      setCurrentBuilding(buildingId)
    }
  }

  const onZoneChange = (zoneId) => {
    if (currentZoneId !== zoneId) {
      setCurrentZone(zoneId)
    }
  }

  const onGXChange = (gxId) => {
    if (currentGXId !== gxId) {
      setCurrentGX(gxId)
    }
  }

  const openModal = (selectModal, scheduleId) => {
    setSelectedModal(selectModal)
    setCurrentScheduleId(scheduleId)
  }

  const initialUrlFetch = showMobile ? 
    (configuration['backend_host'] + '/ahiapi/buildings') 
    : 
    (configuration['backend_host'] + '/ahiapi/campuses')

  function getResponse(response) {
    if (!response.ok) {
      if (response.status === 401) {
        authentication.logout()
        window.location.replace(authentication.logoutLink)
      }
      
      throw new Error('Not Accessible')
    }
    return response.json()
  }

  function processCampusJSON(json) {
    let campuses = []
    if (json) {
      campuses = json
    }

    saveCampuses(campuses)

    if (pageParam !== 'settings' && pageParam !== 'resources') {
      if (campusParam === undefined || campusParam === '' || campusParam === null) {
        setCurrentCampus(campuses[0].campusEntityKey)
        updateCampusData(campuses[0].campusEntityKey)
      } else {
        setCurrentCampus(campusParam)
        updateCampusData(campusParam)
      }
    }
    
    if (pageParam === 'energy') {
      updateGXData(campusParam)
    }

    if (pageParam === 'lobbyKiosk') {
      fetchKioskData()
    }

    if (showMobile) {
      if (campusParam === undefined || campusParam === '' || campusParam === null) {
        setCurrentBuilding(campuses[0].buildings[0].buildingEntityKey)
      } else {
        campuses.filter((campus) => {
          if (campus.campusEntityKey === campusParam) {
            setCurrentBuilding(campus.buildings[0].buildingEntityKey)
          }
        })          
      }
    }
    
    setCampusLoaded(true)
    setShowLoading(false)
  }

  function loadCampuses() {
    fetch(initialUrlFetch, 
      { headers: authenticatedComponent.generateAuthenticatedHeader() }
    )
    .then(getResponse)
    .then(processCampusJSON)

    .catch(error => {
      saveCampuses([])
      console.error(error)

      setShowLoading(true)
      
      setTimeout(() => {
        loadCampuses()
      }, 30000)
    })
  }

  const postAuthentication = () => {
    for (var i = 0; i < sessionStorage.length; i++) {
      var value = sessionStorage.getItem(sessionStorage.key(i))

      if (sessionStorage.key(i).startsWith('state-') && value !== '/') {
        currentUrl = value
        setParams(value)
      } 
   }
    loadCampuses()

    setShowLoading(false)
    processProfileData()
  }

  useEffect(() => {
    if (authentication.isLoggedIn() || !currentUrl.includes('lobbyKiosk')) {
      authentication.handleAuthentication(postAuthentication)
    }
  }, [currentUrl])

  const validateAuthentication = () => {
    authentication.checkExpiration()
  }

  const processProfileData = () => {
    let userData = {
      first_name: '',
      last_name: '',
      email: ''
    }

    function getUserData() {
      fetch(configuration['backend_host'] + '/prefs/' + authentication.getUserId(),
        {
          headers: authenticatedComponent.generateAuthenticatedHeader()
        }
      )

      .then(getResponse)

      .then(data => {
        userData.first_name = data.prefs.first_name
        userData.last_name = data.prefs.last_name
        userData.email =  data.prefs.email
        setUserData(userData)

        sessionStorage.clear()
      })

      .catch(error => {
        saveCampuses([])
        console.error(error)

        setShowLoading(true)
        
        setTimeout(() => {
          getUserData()
        }, 30000)
      })
    }

    setShowLoading(false)
    getUserData()
  }

  if (authentication.isLoggedIn()) {
    if (campusesLoaded) {
      return (
        <div className="App">
          {showLoading && <Loading message="Unable to connect, but we're trying again." />}

          {!currentUrl.includes('lobbyKiosk') && (
            <div className="elexity-banner">
              <img src={elexity_main_logo} alt='elexity logo' width="86" height="20" />
            </div>
          )}
          
          <BrowserRouter>
            {!currentUrl.includes('lobbyKiosk') && 
              (<>
                <Sidebar 
                  showMobile={showMobile} 
                  activeTab={activeTab.toLowerCase()}
                  currentUrl={currentUrl}
                />

                <PageHeader
                  currentUrl={currentUrl}
                  currentCampusData={currentCampusData}
                  currentCampusId={currentCampusId}
                  currentBuildingId={currentBuildingId}
                  currentZoneId={currentZoneId}
                  currentGXId={currentGXId}
                  hasSingleGX={hasSingleGX}
                  onBuildingChange={onBuildingChange}
                  onGXChange={onGXChange}
                  onZoneChange={onZoneChange}
                  activeTab={activeTab.toLowerCase()}
                  onTabChange={setActiveTab}
                  showMobile={showMobile}
                />
              </>)
            }
            
            <Routes>
              <Route 
                index 
                path="/" 
                element={
                  <Homepage 
                    activeTab={activeTab.toLowerCase()}
                    showMobile={showMobile}
                    updateCampusData={() => updateCampusData(currentCampusId)}
                    openModal={openModal}
                    selectedModal={selectedModal}
                    setSelectedModal={setSelectedModal}
                    currentScheduleId={currentScheduleId}
                    setCurrentScheduleId={setCurrentScheduleId}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                } 
              />
              <Route 
                path={showMobile ? 
                  ("/home/:campusId?/:buildingId?/:zoneId?") 
                  : 
                  ("/comfort/:campusId?/:buildingId?/:zoneId?")
                } 
                element={
                  <Homepage 
                    activeTab={activeTab.toLowerCase()}
                    showMobile={showMobile}
                    updateCampusData={() => updateCampusData(currentCampusId)}
                    openModal={openModal}
                    selectedModal={selectedModal}
                    setSelectedModal={setSelectedModal}
                    currentScheduleId={currentScheduleId}
                    setCurrentScheduleId={setCurrentScheduleId}
                    activeFilter={activeFilter}
                    setActiveFilter={setActiveFilter}
                  />
                } 
              />
              <Route 
                path="/energy/:campusId?/:gxId?" 
                element={
                  <Energy 
                    currentCampusId={currentCampusId}
                    currentCampusData={currentCampusData}
                    currentGXId={currentGXId}
                    hasSingleGX={hasSingleGX}
                    currentGXData={currentGXData}
                    onMeterSelect={onMeterSelect}
                    updateGXData={() => updateGXData(currentCampusId)}
                  />
                } 
              />
              <Route 
                path="/schedules/:campusId?" 
                element={
                  <Schedules 
                    openModal={openModal}
                    selectedModal={selectedModal}
                    setSelectedModal={setSelectedModal}
                    currentScheduleId={currentScheduleId}
                    setCurrentScheduleId={setCurrentScheduleId}
                  />
                } 
              />
              <Route path="/events/:campusId?" element={<Events />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/resources" element={<Resources />} />
              <Route 
                path="/lobbyKiosk/:campusId?/:gxId?" 
                element={
                  <LobbyKiosk 
                    currentCampusData={currentCampusData}
                    currentCampusId={currentCampusId}
                    currentGXId={currentGXId}
                    currentGXData={currentGXData}
                    updateGXData={() => fetchKioskData()}
                  />
                } 
              />
            </Routes>
          </BrowserRouter>
        </div>
      )
    } else {
      return <div><Loading message="Loading..." /></div>
    }
  } else {
    if (currentUrl.includes('lobbyKiosk')) {
      return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route 
                path="/lobbyKiosk/:campusId?/:gxId?" 
                element={
                  <LobbyKiosk 
                    currentCampusData={currentCampusData}
                    currentCampusId={currentCampusId}
                    currentGXId={currentGXId}
                    currentGXData={currentGXData}
                    updateGXData={() => fetchKioskData()}
                  />
                } 
              />
            </Routes>
          </BrowserRouter>
        </div>
      )
    }
  } 
}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses,
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentGXData: state.currentGXData,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveCampuses: (campusesData) => {
      dispatch(saveCampuses(campusesData))
    },
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    setCurrentGXData: (gxData) => {
      dispatch(setCurrentGXData(gxData))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    },
    setUserData: (userData) => {
      dispatch(setUserData(userData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)