import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { connect } from 'react-redux'
import configuration from '../config'
import WithRouter from '../utilities/WithRouter'
import authentication from '../utilities/Authentication'
import AuthenticatedComponent from '../utilities/AuthenticatedComponent'
import Message from '../components/Message'
import Loading from '../components/Loading'
import { setUserData, setCurrentCampus } from '../actions'

const SettingsContainer = styled.section`
    overflow: hidden;
    overflow-y: auto;

    .user-settings-container {
        margin: 78px 90px 90px;

        h1, h3 {
            color: #324E55;
        }

        h1 {
            font-size: 20px;
        }

        h3 {
            font-size: 16px;
        }

        .profile-settings,
        .security-settings {
            padding: 24px;
        }

        .field-content {
            display: flex;
            flex-direction: column;

            label {
                padding: 24px 0 12px;
            }

            .field-description {
                color: #324E55;
                font-size: 12px;
                font-style: italic;
                line-height: 20px;
                &.forgot {
                    cursor: pointer;
                    margin-bottom: 3px;
                    text-decoration: underline;
                    transition: ease-in-out all 250ms;
                    &:hover {
                        color: #12B76A;
                        transition: ease-in-out all 250ms;
                    }
                }
            }
        }

        .settings-btn-container {
            max-width: 325px;
            padding-top: 24px;
            width: 100%;

            button {
                border-radius: 8px;
                cursor: pointer;
                font-weight: 600;
                height: 44px;
                transition: ease-in-out all 250ms;
                width: 150px;

                &:hover {
                    filter: brightness(0.9);
                    transition: ease-in-out all 250ms;
                }
            }
            
            button.reset {
                background: white;
                border: 1px solid #D0D5DD;
                color: #324E55;
            }

            button.update {
                background: #F8771A;
                border: none;
                color: white;
                margin-left: 24px;
            }
        }
    }
    
    @media screen and (max-width: 768px) {
        .user-settings-container {
            margin: 78px 50px 90px 50px;
            
            .profile-settings,
            .security-settings {
                padding: 24px 0;
            }

            .settings-btn-container {
                display: flex;
                flex-flow: row wrap;
                height: 125px;
                justify-content: space-between;
                width: 70%;

                button.update {
                    margin-left: 0;
                }
            }
        }
        
    }
`

const Settings = ({ 
    setUserData,
    setCurrentCampus,
    currentUserData,
}) => {
    const [fieldResetTimes, setFieldResetTimes] = useState(0)
    const [profileData, setProfileData] = useState({
        id: authentication.getUserId(),
        prefs: {
            first_name: '',
            last_name: '',
            email: authentication.getEmailAddress(),
        }
    })
    const [passwordData, setPasswordData] = useState({
        current_password: null,
        new_password: null,
        retype_new_password: null
    })
    const [resetProfileData, setResetProfileData] = useState({...profileData})
    const [loading, setLoading] = useState(false)
    const [showPersonalMessage, setShowPersonalMessage] = useState(false)
    const [showSecurityMessage, setShowSecurityMessage] = useState(false)
    const [messageText, setMessageText] = useState(null)
    const [messageType, setMessageType] = useState(null)

    const authenticatedComponent = new AuthenticatedComponent
    const configUrl = configuration.auth0_domain
    const configClientId = configuration.auth0_clientid

    const fillUserProfile = (prefData) => {
        setLoading(true)
        if (prefData.prefs) {
            Object.keys(profileData.prefs).forEach(fieldName => {
                if (prefData.prefs[fieldName]) {
                    profileData.prefs[fieldName] = prefData.prefs[fieldName]
                }
            })

            setProfileData({...profileData})
            setFieldResetTimes(fieldResetTimes+1)
            setResetProfileData({...profileData})

            setLoading(false)
        }
    }

    useEffect(() => {
        setCurrentCampus(null)
        if (authentication.getName()) {
            const userNameSplit = authentication.getName().split(' ')
            profileData.prefs.first_name = userNameSplit.shift()
            profileData.prefs.last_name = userNameSplit.join(' ')
        }

        authenticatedComponent.getUserProfile(fillUserProfile)
    }, [])

    const fieldUpdate = (e) => {
        const eTarget = e.target
        profileData.prefs[eTarget.name] = eTarget.value
    }

    const handleForgotPassword = () => {
        setLoading(true)

        let forgotPasswordData = {
            "client_id": configClientId,
            "email": profileData.prefs.email,
            "connection": "Username-Password-Authentication",
        }

        fetch (
            `https://${configUrl}/dbconnections/change_password`,

            {
              headers: authenticatedComponent.generateAuthenticatedHeader(true),
              method: 'POST',
              body: JSON.stringify(forgotPasswordData)
            }
          )

        .then(response => {
            if (response.ok) {
                // response return no content on success
                successPasswordUpdate('A password reset email has been sent to you.')
                setLoading(false)
                setTimeout(() => {
                    setShowSecurityMessage(false)
                }, 8000)
            }
            else {
                setShowSecurityMessage(true)
                setMessageText('There was an error processing your request.')
                setMessageType('error')
                setLoading(false)
                setTimeout(() => {
                    setShowSecurityMessage(false)
                }, 8000)
            }
        })

        .catch(error => {
            setLoading(false)
            console.error(error)
        })
    }

    const onPasswordChange = (e, name) => {
        Object.keys(passwordData).forEach((key) => {
            if (key === name) {
                setPasswordData(
                    prevState => ({
                    ...prevState,
                    [name]: e.target.value,
                    })
                )
            }
        })
    }

    const submitPassword = () => {
        setLoading(true)

        const validationErrors = []
        if (passwordData.new_password !== passwordData.retype_new_password) {
            validationErrors.push('Retype new password does not match.')
        }

        if (validationErrors.length === 0) {
            const postData = {
            lastPassword: passwordData.current_password,
            nextPassword: passwordData.new_password
            }

            fetch (
            configuration['backend_host'] 
            + '/passwords/' 
            + authentication.getUserId() 
            + '/change',
            {
                headers: authenticatedComponent.generateAuthenticatedHeader(true),
                method: 'POST',
                body: JSON.stringify(postData)
            }
            )
            .then(response => {
                if (response.ok) {
                    // response return no content on success
                    successPasswordUpdate('Password has been updated!')
                    setLoading(false)
                }
                else {
                    setShowSecurityMessage(true)
                    setMessageText('Error updating password. Current password may have been incorrect.')
                    setMessageType('error')
                    setLoading(false)
                }
            })

            .catch(error => {
                setLoading(false)
                console.error(error)
            })
        } else {
            setShowSecurityMessage(true)
            setMessageText(validationErrors.join('<br />'))
            setMessageType('error')
        }
    }

    const successPasswordUpdate = ( message ) => {
        setShowSecurityMessage(true)
        setMessageText(message)
        setMessageType('success')
    }

    const resetForm = () => {
        setProfileData({...resetProfileData})
        setFieldResetTimes(fieldResetTimes+1)
    }

    const saveProfile = () => {
        fetch (
            configuration['backend_host'] 
            + '/prefs/' 
            + authentication.getUserId(),

            {
              headers: authenticatedComponent.generateAuthenticatedHeader(true),
              method: 'POST',
              body: JSON.stringify(profileData)
            }
          )

        .then(response => {
            if (response.ok) {
                setShowPersonalMessage(true)
                setMessageText('Your profile was updated!')
                setMessageType('success')

                setTimeout(() => {
                    setShowPersonalMessage(false)
                }, 5000)
            }
            else {
                setShowPersonalMessage(true)
                setMessageText('There was an error processing your request.')
                setMessageType('error')
                setLoading(false)
                setTimeout(() => {
                    setShowPersonalMessage(false)
                }, 8000)
            }
        })

        .catch(error => {
            setLoading(false)
            console.error(error)
        })
    }

    return (
        <SettingsContainer>
            {loading && (
                <Loading message='Processing request' />
            )}
            <div className="user-settings-container">
                <h1>User Settings</h1>
                <div className="profile-settings">
                    <h3>Personal Settings</h3>

                    {showPersonalMessage && (
                        <Message displayMessage={messageText} type={messageType} />
                    )}
                    <div className="field-container">
                        <div className="field-content">
                            <label>First Name</label>
                            <input
                                type="text"
                                className="txt-name"
                                name="first_name"
                                key={'first_name' + fieldResetTimes}
                                onChange={fieldUpdate}
                                defaultValue={profileData.prefs.first_name}
                                placeholder="First name"
                            />

                            <label className="show-mobile">Last Name</label>
                            <input
                                type="text"
                                className="txt-name"
                                name="last_name"
                                key={'last_name' + fieldResetTimes}
                                onChange={fieldUpdate}
                                defaultValue={profileData.prefs.last_name}
                                placeholder="Last name"
                            />
                        </div>
                    </div>
                
                    <div className="field-container">
                        <div className="field-content">
                            <label>Email address</label>
                            <input
                                type="email"
                                className="txt-email-address"
                                name="email"
                                key={'email' + fieldResetTimes}
                                placeholder="test@test.com"
                                onChange={fieldUpdate}
                                defaultValue={profileData.prefs.email}
                                // disabled
                            />
                        </div>
                    </div>

                    <div className="settings-btn-container setting-footer-btns">
                        <button className="reset" onClick={resetForm}>
                            Reset
                        </button>
                        <button className="update" onClick={saveProfile}>
                            Update Profile
                        </button>
                    </div>
                </div>

                <div className="security-settings">
                    <h3>Security Settings</h3>

                    {showSecurityMessage && (
                    <Message displayMessage={messageText} type={messageType} />
                    )}

                    <div className="field-container">
                        <div className="field-content">
                            <label>Current Password</label>
                            <div className="field-description forgot" onClick={handleForgotPassword}>
                            Forgot Password?
                            </div>
                            <input
                                type="password"
                                className="txt-password"
                                name="current_password"
                                placeholder="*********************"
                                autoComplete="Off"
                                onChange={(e) => onPasswordChange(e, 'current_password')}
                            />
                        </div>
                    </div>

                    <div className="field-container">
                        <div className="field-content">
                            <label>New Password</label>
                            <div className="field-description">
                                Your new password must be more than 8 characters.
                            </div>
                            <input
                                type="password"
                                className="txt-password"
                                name="new_password"
                                key="new_password"
                                placeholder="*********************"
                                autoComplete="Off"
                                onChange={(e) => onPasswordChange(e, 'new_password')}
                            />
                        </div>
                    </div>

                    <div className="field-container">
                        <div className="field-content">
                            <label>Retype New Password</label>
                            <input
                                type="password"
                                className="txt-password"
                                name="retype_new_password"
                                key="retype_new_password"
                                placeholder="*********************"
                                autoComplete="Off"
                                onChange={(e) => onPasswordChange(e, 'retype_new_password')}
                            />
                        </div>
                    </div>

                    <div className="settings-btn-container setting-footer-btns">
                        <button
                            className="reset"
                        >
                            Cancel
                        </button>

                        <button
                            className="update"
                            onClick={submitPassword}
                        >
                            Update Password
                        </button>
                    </div>
                </div>
            </div>
        </SettingsContainer>
    )
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (userData) => {
      dispatch(setUserData(userData))
    },
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Settings))