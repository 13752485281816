import React, { useEffect } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'

import MeterCardContainer from '../components/MeterCardContainer'
import KioskModel from '../components/KioskModel'
import EnergyUtilityMeterDetail from './subsections/EnergyUtilityMeterDetail'
import { setCurrentGX } from '../actions'
import { connect } from 'react-redux'
import WithRouter from '../utilities/WithRouter'

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

const Energy = ({ 
    currentCampusData,
    currentCampusId,
    currentGXId,
    hasSingleGX,
    currentGXData,
    onMeterSelect,
    router,
    updateGXData,
}) => {
    exportingModule(Highcharts)

    let currentSelectedMeterData

    useEffect(() => {
        if (currentGXData?.gridInterconnects?.length === 1 
            && currentGXId !== currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey) {
            onMeterSelect(currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey)
        }
    }, [])

    //Call energy API when campus change
    useEffect(() => {
        updateGXData(currentCampusId)
    }, [currentCampusId])
    
    //When gx data updates, clear graphs and reload
    useEffect(() => {
        if (currentGXData?.gridInterconnects?.length === 1 
            && currentGXId !== currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey) {
            onMeterSelect(currentGXData.gridInterconnects[0].gridInterconnectDescription.gridInterconnectEntityKey)
        }

        //Graph data update every 5 seconds
        if ((currentGXId === null && !hasSingleGX) || (currentGXId !== null && hasSingleGX)) {
            let interval = setInterval(() => {
                updateGXData(currentCampusId)
                }, 5000)
        
                return () => clearInterval(interval)
        }
    }, [currentGXData])

    const navigateWithValidation = (newPath) => {
        if (router.location.pathname !== newPath) {
            router.navigate(newPath)
        }
    }

    const handleMeterSelect = (selectedMeter) => {
        onMeterSelect(selectedMeter)
        navigateWithValidation('/energy/' + currentCampusId + '/' + selectedMeter)
    }

    return (
        <section className="energy-section">
            {currentCampusData && currentGXData && (!currentGXId || hasSingleGX) ? (
                <div className="utility-meter-main">
                    <h1 className="campus-name">{currentCampusData?.campusDescription?.longCampusDisplayName}</h1>

                    {currentGXData?.gridInterconnects?.map((gx) => (
                        <div 
                            className="meter-row"
                            key={`${gx.gridInterconnectDescription.gridInterconnectEntityKey}-meter-container`}
                        >
                            <div className='meter-name-container'>
                                <div className='meter-name'>
                                    {gx.gridInterconnectDescription.longGridInterconnectDisplayName ? 
                                        gx.gridInterconnectDescription.longGridInterconnectDisplayName 
                                        : 
                                        'Utility Meter'
                                    }
                                </div>

                                {currentGXData?.gridInterconnects.length > 1 && (
                                    <div 
                                        className="meter-button"
                                        onClick={() => handleMeterSelect(gx.gridInterconnectDescription.gridInterconnectEntityKey)}
                                    >
                                        View Meter
                                    </div>
                                )}
                            </div>

                            <div className='meter-container'>
                                <MeterCardContainer
                                    currentMeterData={currentSelectedMeterData}
                                    gridPower={gx.kiosk.gridkW}
                                    solarPower={gx.kiosk.solarkW}
                                    batteryPower={gx.kiosk.batterykW}
                                    batterySoc={gx.kiosk.batterySoc}
                                    hasSolar={gx.kiosk.hasSolar}
                                    hasBattery={gx.kiosk.hasBattery}
                                    hasEv={gx.kiosk.hasEvCharger}
                                    evPower={gx.kiosk.evChargerkW}
                                    hvacPower={gx.kiosk.hvackW}
                                    otherPower={gx.kiosk.unmanagedkW}
                                />

                                <KioskModel
                                    gxPower={gx.kiosk.gridkW}
                                    hasSolar={gx.kiosk.hasSolar}
                                    solarPower={gx.kiosk.solarkW}
                                    hasBattery={gx.kiosk.hasBattery}
                                    batteryDisplaySoc={gx.kiosk.batteryDisplaySoc}
                                    batterySoc={gx.kiosk.batterySoc}
                                    batteryPower={gx.kiosk.batterykW}
                                    hasEv={gx.kiosk.hasEvCharger}
                                    hasEvConnected={gx.kiosk.hasEvConnected}
                                    evPower={gx.kiosk.evChargerkW}
                                    hvacPower={gx.kiosk.hvackW}
                                    otherPower={gx.kiosk.unmanagedkW}
                                />
                            </div>

                            {hasSingleGX && (
                                <div className='utility-meter-detail no-margin'>
                                    <EnergyUtilityMeterDetail
                                        currentCampusId={currentCampusId}
                                        currentGXData={currentGXData}
                                        meterSelected={currentGXId}
                                        updateGXData={updateGXData}
                                        hasSingleGX={hasSingleGX}
                                    />
                                </div>
                            )}
                            
                        </div>
                    ))}
                </div>
            ) 
            : 
            (
                <div className='utility-meter-detail'>
                    <EnergyUtilityMeterDetail
                        currentCampusId={currentCampusId}
                        currentGXData={currentGXData}
                        meterSelected={currentGXId}
                        updateGXData={updateGXData}
                    />
                </div>
            )}

            <a
                className='hidden-lobby-kiosk'
                href={`/lobbyKiosk/${currentCampusId}/${currentGXId}`}
            >
                LobbyKiosk
            </a>
        </section>
    )
}

const mapStateToProps = (state) => {
    return {
        campuses: state.campuses,
        currentCampusId: state.currentCampusId,
        currentGXId: state.currentGXId,
        currentCampusData: state.currentCampusData,
        currentGXData: state.currentGXData
    }
}
  
// used by parent class AuthenticatedComponent
const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentGX: (gxId) => {
        dispatch(setCurrentGX(gxId))
        },
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Energy))