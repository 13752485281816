import React, { useEffect } from 'react'
import {connect} from 'react-redux'
import styled from '@emotion/styled'

import WithRouter from '../utilities/WithRouter'
import { setCurrentCampus } from '../actions'

import orientationVideo from '../assets/orientation_video.webm'
import adaptiveEnergyVideo from '../assets/adaptive_energy_management.webm'
import hvacClimateControls from '../assets/hvac_climate_controls.webm'

const ResourcesDiv = styled.div`
  color: #324E55;
  height: 100vh;
  margin: 78px 0 0 90px;
  overflow-x: scroll;

  h1 {
    font-size: 20px;
  }

  .resources-section {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
  }

  .resource-video {
    video {
      height: auto;
      width: clamp(300px, 39vw, 500px);
    }
  }
`

const Resources = ({ setCurrentCampus }) => {
  useEffect(() => {
    setCurrentCampus(null)
  }, [])

    return (
      <ResourcesDiv>
        <h1>Tutorial Videos</h1>

        <section className="resources-section">
          <div className="resource-video">
            <h4>90 Second Orientation</h4>
            <video src={orientationVideo} width='320' height='240' controls />
          </div>

          <div className="resource-video">
            <h4>Adaptive Energy Management</h4>
            <video src={adaptiveEnergyVideo} width='320' height='240' controls />
          </div>
          
          <div className="resource-video">
            <h4>HVAC Climate Controls</h4>
            <video src={hvacClimateControls} width='320' height='240' controls />
          </div>
        </section>
      </ResourcesDiv>
    )
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
        dispatch(setCurrentCampus(campusId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Resources))