import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import authentication from '../../utilities/Authentication'
import configuration from '../../config'

import Modal from '../Modal'
import TempModeDefaults from './TempModeDefaults'
import ScheduleEventName from './ScheduleEventName'
import ScheduleDays from './ScheduleDays'
import StartEndTime from './StartEndTime'
import LowerUpperMode from './LowerUpperMode'

import icon_schedule from '../../assets/icon_schedule.svg'
import icon_plus from '../../assets/icon_plus.svg'
import AuthenticatedComponent from '../../utilities/AuthenticatedComponent'

const EditSchedule = styled.div`
    .edit-schedule-settings {
        border-bottom: 2px solid #EAECF0;
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        width: 100%;

        & > div:nth-of-type(1),
        & > div:nth-of-type(3) {
            width: 30%
        }

        & > div:nth-of-type(2) {
            width: 28%
        }

        .end-container {
            align-items: flex-end;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            .remove {
            cursor: pointer;
            font-size: 12px;
            text-decoration: underline;
            }
        }
    }
`

const AddSchedule = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 24px;

    .add-button-container {
        align-items: center;
        border: 1px solid #D9D9D9;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        height: 25px;
        justify-content: center;
        transition: ease-in-out all 250ms;
        width: 25px;
        &:hover {
            background: #EAECF0;
            transition: ease-in-out all 250ms;
        }
    }
`

const ScheduleList = styled.div`
    // max-height: 428px;
    // overflow: hidden;
    // overflow-y: auto;
`

const EditScheduleModal = ({
    modalTitle,
    clearModal,
    showMobile,
    pageName,
    currentCampusId,
    selectedScheduleData,
    setProcessingList,
    scheduleCallback,
    setErrorMessage
}) => {
    const authenticatedComponent = new AuthenticatedComponent
    const newDate = new Date()
    let year = newDate.toLocaleString("default", { year: "numeric" });
    let month = newDate.toLocaleString("default", { month: "2-digit" });
    let day = newDate.toLocaleString("default", { day: "2-digit" });
    let formattedDate = year + "-" + month + "-" + day;

    const [scheduleName, setScheduleName] = useState(selectedScheduleData && selectedScheduleData?.scheduleName)
    const [startDate, setStartDate] = useState(newDate)
    const [mainForm, setMainForm] = useState({
        scheduleName: null,
        scheduleId: null,
        defaultInstruction: {
            blockCooling: null,
            blockHeating: null,
            lowerBound: null,
            upperBound: null
        },
        daySchedules: [],
        dayScheduleExceptions: [],
    })
    const [formValues, setFormValues] = useState([])
    const hasDaysSelected = mainForm.daySchedules.every(element => element.daySelectors.length !== 0);

    useEffect(() => {
        if (selectedScheduleData !== null) {
            setScheduleName(selectedScheduleData.scheduleName)

            setMainForm({
                scheduleName: selectedScheduleData.scheduleName,
                scheduleId: selectedScheduleData.scheduleId,
                defaultInstruction: {
                    blockCooling: selectedScheduleData.defaultInstruction.blockCooling,
                    blockHeating: selectedScheduleData.defaultInstruction.blockHeating,
                    lowerBound: selectedScheduleData.defaultInstruction.lowerBound,
                    upperBound: selectedScheduleData.defaultInstruction.upperBound
                },
                daySchedules: selectedScheduleData.daySchedules,
                dayScheduleExceptions: selectedScheduleData.dayScheduleExceptions,
            })

            if (selectedScheduleData.daySchedules && mainForm.daySchedules.length === 0) {
                selectedScheduleData.daySchedules.map((schedule) => {
                    formValues.push(schedule)
                })
            }
            
            if (selectedScheduleData.dayScheduleExceptions && mainForm.dayScheduleExceptions.length === 0) {
                selectedScheduleData.dayScheduleExceptions.map((schedule) => {
                    formValues.push(schedule)
                })
            }
        }
    }, [selectedScheduleData])
    

    const handleRemove = (e, index) => {
        let item = e.currentTarget.parentNode.parentNode

        formValues.splice(index, 1)
        item.remove()
    }

    const handleAdd = () => {
        setFormValues([...formValues, {
            entries: [{
            fromHour: 6,
            fromMinute: 0,
            toHour: 14,
            toMinute: 30,
            instruction: {
                blockCooling: false,
                blockHeating: false,
                lowerBound: 68,
                upperBound: 75
            }
            }],
            narrowingDurationInMinutes: 180,
            wideningDurationInMinutes: 0,
            daySelectors: []
        }])
    }

    const handleName = (name) => {
        setScheduleName(name)

        setMainForm((mainForm) => ({...mainForm,
            scheduleName: name
        }))
    }

    const handleChange = (index, name, value) => {
        let newFormValues = [...formValues]

        //default lowerbound upperbound mode
        if (name === 'defaultLowerBound') {
            setMainForm((mainForm) => (
                {...mainForm, 
                    defaultInstruction: {
                        ...mainForm.defaultInstruction,
                        lowerBound: value
                    }
                }
            ))
        } else if (name === 'defaultUpperBound') {
            setMainForm((mainForm) => (
                {...mainForm, 
                    defaultInstruction: {
                        ...mainForm.defaultInstruction,
                        upperBound: value
                    }
                }
            ))
        } else if (name === 'defaultMode') {
            if (value === 'heat') {
                setMainForm((mainForm) => (
                    {...mainForm, 
                        defaultInstruction: {
                            ...mainForm.defaultInstruction,
                            blockCooling: true,
                            blockHeating: false
                        }
                    }
                ))
            } else if (value === 'cool') {
                setMainForm((mainForm) => (
                    {...mainForm, 
                        defaultInstruction: {
                            ...mainForm.defaultInstruction,
                            blockCooling: false,
                            blockHeating: true
                        }
                    }
                ))
            } else if (value === 'fan') {
                setMainForm((mainForm) => (
                {...mainForm, 
                    defaultInstruction: {
                        ...mainForm.defaultInstruction,
                        blockCooling: true,
                        blockHeating: true
                    }
                }
                ))
            } else if (value === 'auto') {
                setMainForm((mainForm) => (
                {...mainForm, 
                    defaultInstruction: {
                        ...mainForm.defaultInstruction,
                        blockCooling: false,
                        blockHeating: false
                    }
                }
                ))
            }
        }

        // lowerbound upperbound mode
        if (name === 'lowerBound') {
            newFormValues[index].entries[0].instruction.lowerBound = value
        } else if (name === 'upperBound') {
            newFormValues[index].entries[0].instruction.upperBound = value
        } else if (name === 'heat') {
            newFormValues[index].entries[0].instruction.blockCooling = true
            newFormValues[index].entries[0].instruction.blockHeating = false
        } else if (name === 'cool') {
            newFormValues[index].entries[0].instruction.blockCooling = false
            newFormValues[index].entries[0].instruction.blockHeating = true
        } else if (name === 'fan') {
            newFormValues[index].entries[0].instruction.blockCooling = true
            newFormValues[index].entries[0].instruction.blockHeating = true
        } else if (name === 'auto') {
            newFormValues[index].entries[0].instruction.blockCooling = false
            newFormValues[index].entries[0].instruction.blockHeating = false
        }

        // //start end time
        if (name === 'startTime' || name === 'endTime') {
            let newHour = value.split(":")[0]
            let newMin = value.substring(value.indexOf(':') + 1)
            
            if (newHour.startsWith(0)) {
            newHour = newHour.slice(1)
            } else if (newMin.startsWith(0)) {
            newMin = newMin.slice(1)
            }

            if (name === 'startTime') {
            newFormValues[index].entries[0].fromHour = parseInt(newHour)
            newFormValues[index].entries[0].fromMinute = parseInt(newMin)
            } else {
            newFormValues[index].entries[0].toHour = parseInt(newHour)
            newFormValues[index].entries[0].toMinute = parseInt(newMin)
            }
        }

        setFormValues(newFormValues)

        //day selectors
        if (mainForm) {
            if (name === 'scheduleDays') {
                newFormValues[index].daySelectors = value[0]
                
                setMainForm((mainForm) => ({...mainForm, daySchedules: newFormValues}))
            }

            if (name === 'scheduleDaysException') {
                if (value[1] !== undefined) {
                    newFormValues[index].daySelectors = value[0]
                    newFormValues[index].fromDay = value[1].fromDay
                    newFormValues[index].fromMonth = value[1].fromMonth
                    newFormValues[index].toDay = value[1].toDay
                    newFormValues[index].toMonth = value[1].toMonth
                    setMainForm((mainForm) => ({...mainForm, dayScheduleExceptions: newFormValues}))
                } 
            }
        }
    }

    const saveUpdatedSchedule = () => {
        let newDaySchedules = []
        let newDayScheduleExceptions = []

        //filter daySchedules
        if (mainForm.daySchedules) {
            mainForm.daySchedules.map((schedule) => {
                if (!schedule.fromDay) {
                    newDaySchedules.push(schedule)
                }
            })
        }
        //filter dayScheduleExceptions
        if (mainForm.dayScheduleExceptions) {
            mainForm.dayScheduleExceptions.map((schedule) => {
                if (schedule.fromDay) {
                    newDayScheduleExceptions.push(schedule)
                }
            })
        }
        
        setMainForm(mainForm.daySchedules = newDaySchedules)
        setMainForm(mainForm.dayScheduleExceptions = newDayScheduleExceptions)
        setMainForm(mainForm.scheduleName = scheduleName)

        // Send data to the backend via POST
        setProcessingList(true)
        fetch(configuration['backend_host'] + '/ahiapi/'
        + currentCampusId + '/schedule', 
        {
            headers: authenticatedComponent.generateAuthenticatedHeader(true),
            method: 'POST', 
            body: JSON.stringify(mainForm)
        })

        .then(async response => {
            if (!response.ok) {
                const res = await response.json()

                setErrorMessage(res.message)

                setTimeout(() => {
                    setErrorMessage(null)
                }, 9000)
                throw new Error(res.message)
            } else {
                setProcessingList(false)
            }
            return response.json()
            })

        .then(() => {
            authenticatedComponent.getScheduleData(currentCampusId, 'large', scheduleCallback)
        })
    
        .catch(error => {
            setProcessingList(false)
            console.error(error)
        })
    }

    return (
        <Modal
            modalTitle={modalTitle}
            modalDescription={"Edit your schedule here."}
            modalImage={icon_schedule}
            clearModal={clearModal}
            updateSettings={saveUpdatedSchedule}
            shouldDisable={!hasDaysSelected}
            showMobile={showMobile}
            renderModalContent={(<>
                {mainForm && mainForm.defaultInstruction && mainForm.defaultInstruction.blockCooling !== null && (
                    <TempModeDefaults
                        selectedDefaultBlockCooling={mainForm.defaultInstruction.blockCooling}
                        selectedDefaultBlockHeating={mainForm.defaultInstruction.blockHeating}
                        selectedDefaultLowerBound={mainForm.defaultInstruction.lowerBound}
                        selectedDefaultUpperBound={mainForm.defaultInstruction.upperBound}
                        handleChange={handleChange}
                    />
                )}
                
                <ScheduleEventName 
                    scheduleName={mainForm && mainForm.scheduleName}
                    pageName={pageName}
                    handleName={handleName}
                />
                
                <ScheduleList className="schedule-list-container">
                    {/* Form Additions */}
                    {formValues.map((schedule, index) => (
                        <EditSchedule className="edit-schedule-container" key={index} data-value={index}>
                            <div className="edit-schedule-settings">
                                <ScheduleDays
                                    selectedDays={schedule.daySelectors}
                                    isException={schedule.fromDay ? true : false}
                                    selectedFromDay={schedule.fromDay}
                                    selectedFromMonth={schedule.fromMonth}
                                    selectedToDay={schedule.toDay}
                                    selectedToMonth={schedule.toMonth}
                                    handleChange={handleChange}
                                    index={index}
                                />
                                <StartEndTime 
                                    selectedFromHour={schedule.entries[0].fromHour}
                                    selectedFromMin={schedule.entries[0].fromMinute}
                                    selectedToHour={schedule.entries[0].toHour}
                                    selectedToMin={schedule.entries[0].toMinute}
                                    handleChange={handleChange}
                                    index={index}
                                />
                        
                                <div className="end-container">
                                    <LowerUpperMode 
                                        selectedLower={schedule.entries[0].instruction.lowerBound}
                                        selectedUpper={schedule.entries[0].instruction.upperBound}
                                        selectedBlockCooling={schedule.entries[0].instruction.blockCooling}
                                        selectedBlockHeating={schedule.entries[0].instruction.blockHeating}
                                        handleChange={handleChange}
                                        index={index}
                                    />
                        
                                {index !== 0 && (
                                    <div 
                                        className="remove"
                                        onClick={(e) => handleRemove(e, index)}
                                    >
                                        Remove
                                    </div>
                                )}
                                </div>
                            </div>
                        </EditSchedule>
                    ))}
                </ScheduleList>
                
                <AddSchedule className="add-schedule">
                    <div 
                    className="add-button-container"
                    onClick={() => handleAdd()}
                    >
                    <img
                        src={icon_plus}
                        alt="button to add another schedule"
                        height={18}
                        width={18}
                    />
                    </div>
                </AddSchedule>
            </>)}
        /> 
    )
}

export default EditScheduleModal