import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'

import Message from '../components/Message'
import Loading from '../components/Loading'
import CreateScheduleModal from '../components/modals/CreateScheduleModal'
import EditScheduleModal from '../components/modals/EditScheduleModal'
import ConfirmModal from '../components/modals/ConfirmModal'

import AuthenticatedComponent from '../utilities/AuthenticatedComponent'
import WithRouter from '../utilities/WithRouter'
import authentication from '../utilities/Authentication'
import configuration from '../config'
import { updateCurrentCampusData } from '../actions'
import icon_plus from  '../assets/icon_plus.svg'

const Schedules = ({
  currentCampusData,
  currentCampusId,
  openModal,
  selectedModal,
  setSelectedModal,
  currentScheduleId,
  setCurrentScheduleId
}) => {
  const [processingList, setProcessingList] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [processingAssignment, setProcessingAssignment] = useState(false)
  const [currentScheduleData, setCurrentScheduleData] = useState([])
  const [selectedScheduleData, setSelectedScheduleData] = useState(null)
  const [scheduleAssignments, setScheduleAssignments] = useState([])
  const [duplicateForm, setDuplicateForm] = useState({})
  const [scheduleIdDelete, setScheduleIdDelete] = useState(null)

  const authenticatedComponent = new AuthenticatedComponent

  useEffect(() => {
    authenticatedComponent.getScheduleData(currentCampusId, 'large', scheduleCallback)
    authenticatedComponent.getScheduleAssignments(currentCampusId, assignmentCallback)

    if (sessionStorage.scheduleId) {
      openModal('edit-schedule', currentScheduleId)
      authenticatedComponent.getSelectedScheduleData(currentCampusId, currentScheduleId, selectedCallback)
    }
  }, [])

  useEffect(() => {
    authenticatedComponent.getScheduleData(currentCampusId, 'large', scheduleCallback)
    authenticatedComponent.getScheduleAssignments(currentCampusId, assignmentCallback)
  }, [currentCampusId])

  const scheduleCallback = (data) => {
    setCurrentScheduleData(data)
  }

  const assignmentCallback = (data) => {
    setScheduleAssignments(data)
  }
  
  const selectedCallback = (data) => {
    setSelectedScheduleData(data)
  }

  const handleAssignment = (e, zoneKey) => {
    setProcessingAssignment(true)

    const generateAuthenticatedHeader = (addPostHeader) => {
      const headerObj = authentication.generateHeaderObject()
      const authenticatedHeaders = new Headers(headerObj)
      if (addPostHeader) {
        authenticatedHeaders.set('Content-Type', 'application/x-www-form-urlencoded')
      }
      return authenticatedHeaders
    }

    let newScheduleName = encodeURIComponent(e.target.value)
  
    // Send data to the backend via POST
    fetch(configuration['backend_host'] + '/ahiapi/'
    + zoneKey
    + '/thermalZone/configuration', 
    {
      headers: generateAuthenticatedHeader(true),
      method: 'POST', 
      body: `scheduleName=${newScheduleName}`
    })
  
    .then(response => {
      response.json()
    })

    .then(() => {
      authenticatedComponent.getScheduleAssignments(currentCampusId, assignmentCallback)
      setProcessingAssignment(false)
    })
  }

  const confirmDelete = (scheduleId) => {
    setScheduleIdDelete(scheduleId)
    openModal('confirm')
  }

  const handleDelete = () => {
    setProcessingList(true)

    // Send delete request to the backend via DELETE
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentCampusId + '/schedule/' + scheduleIdDelete, 
    {
        headers: authenticatedComponent.generateAuthenticatedHeader(true),
        method: 'DELETE', 
        body: null
    })

    .then(response => {
      if (!response.ok) {
        throw new Error('Error deleting the schedule.')
      }
      return response.json()
    })

    .then(() => {
      authenticatedComponent.getScheduleData(currentCampusId, 'large', scheduleCallback)
      setScheduleIdDelete(null)  
      setProcessingList(false)
      clearModal()
    })

    .catch(error => {
      console.error(error)
    })
  }

  const clearModal = () => {
    setSelectedModal(null)
    setSelectedScheduleData(null)
    setCurrentScheduleId(null)

    sessionStorage.removeItem('scheduleId')
  }

  const handleEditModal = (scheduleId) => {
    setCurrentScheduleId(scheduleId)
    openModal('edit-schedule', scheduleId)

    authenticatedComponent.getSelectedScheduleData(currentCampusId, scheduleId, selectedCallback)
  }

  const handleDuplicate = (scheduleId) => {
    //get selected schedule
    fetch(configuration['backend_host'] + '/ahiapi/'
    + currentCampusId
    + '/schedule/'
    + scheduleId, 
    {
      headers: authenticatedComponent.generateAuthenticatedHeader(true),
    })

    .then(response => {
      if (!response.ok) {
        throw new Error('Error duplicating the schedule.')
      }
      
      return response.json()
    })

    .then(data => {
      let newSchedule = data

      newSchedule = {...newSchedule, 
        scheduleName: `${newSchedule.scheduleName} - Duplicate`,
        scheduleId: null
      }
      setDuplicateForm(newSchedule)
    })

    .catch(error => console.error(error))
  }

  useEffect(() => {
    if (Object.keys(duplicateForm).length !== 0) {
      setProcessingList(true)
    
      fetch(configuration['backend_host'] + '/ahiapi/'
      + currentCampusId
      + '/schedule', 
      {
        headers: authenticatedComponent.generateAuthenticatedHeader(true),
        method: 'POST', 
        body: JSON.stringify(duplicateForm)
      })
    
      .then(async response => {
        if (!response.ok) {
          const res = await response.json()
  
          setErrorMessage(res.message)
  
          setTimeout(() => {
            setErrorMessage(null)
          }, 9000)
          throw new Error(res.message)
        } else {
          setProcessingList(false)
          return response.json()
        }
      })
  
      .then(() => {
        setDuplicateForm({})
        authenticatedComponent.getScheduleData(currentCampusId, 'large', scheduleCallback)
      })
  
      .catch(error => {
        setProcessingList(false)
        console.error(error)
      })
    }
    
  }, [duplicateForm])

  const renderModal = () => {
    switch(selectedModal) {
      case 'create-schedule':
        return (
          <CreateScheduleModal
            modalTitle="Create a Schedule"
            pageName="Schedules"
            clearModal={clearModal}
            currentCampusId={currentCampusId}
            setProcessingList={setProcessingList}
            scheduleCallback={scheduleCallback}
            setErrorMessage={setErrorMessage}
          />
        )
      case 'edit-schedule':
        return (
          <EditScheduleModal
            modalTitle="Schedule Editor"
            pageName="Schedules"
            clearModal={clearModal}
            currentCampusId={currentCampusId}
            selectedScheduleData={selectedScheduleData}
            setProcessingList={setProcessingList}
            scheduleCallback={scheduleCallback}
            setErrorMessage={setErrorMessage}
          />
        )
      case 'confirm':
        return <ConfirmModal 
          modalMessage={'Are you sure you want to delete this schedule?'}
          modalAction={() => handleDelete()}
          clearModal={clearModal}
          />
      default:
        return false
    }
  }
  
  const modal = renderModal()
  return (<>
    {processingList && (<Loading message='Processing request' />)}
    {errorMessage && (<Message displayMessage={errorMessage} type='error' />)}

    <section className={processingList ? "schedules processing" : "schedules"}>
      <div className="schedules-container">
        {currentCampusData && currentCampusData.campusDescription && (
          <h1 className="campus-name">{currentCampusData.campusDescription.longCampusDisplayName}</h1>
        )}
        <div className="schedule-list-container main-containers">
          <div className="schedule-header">
            <div className="title-add-button">
              <h3>Schedules List</h3>
              <div className="add-button" onClick={() => openModal('create-schedule', null)}>
                <img src={icon_plus} alt="button to add a new schedule" height={18} width={18} />
              </div>
            </div>
            
            <hr />
          </div>
          
          <div className="schedule-list-table schedules-table">
            <table>
              <thead>
                  <tr>
                      <th>Schedule Name</th>
                      <th>Days</th>
                      <th>Start - End</th>
                      <th>Temperature Range</th>
                      <th>Mode</th>
                      <th></th>
                  </tr>
              </thead>
              
              <tbody>
                {currentScheduleData && currentScheduleData.map((schedule) => (
                  schedule.details.length <= 1 ? (
                    schedule.details.map((detail) => (
                      <tr className='title-row single-entry' key={`${schedule.scheduleId}-${schedule.scheduleName}`}>
                        <td>{schedule.scheduleName}</td>
                      
                        <td>{detail.days}</td>
                        <td>{detail.times}</td>
                        <td>{detail.temps}</td>
                        <td className='detail-mode'>
                          <div className={detail.mode.toLowerCase().replace(/[\s;]+/g, "-")}>
                            {detail.mode}
                          </div>
                        </td>

                        <td className='edit-delete-container'>
                          <div 
                            className="delete"
                            onClick={() => confirmDelete(schedule.scheduleId)}
                          >
                            Delete
                          </div>

                          <div 
                            className="duplicate"
                            onClick={() => handleDuplicate(schedule.scheduleId)}
                          >
                          Duplicate
                          </div>

                          <div 
                            className="edit"
                            onClick={() => handleEditModal(schedule.scheduleId)}
                          >
                          View/Edit
                          </div>
                        </td>
                      </tr>
                    )) 
                  )
                  : 
                  (<Fragment key={`${schedule.scheduleId}-${schedule.scheduleName}`}>
                    <tr className='title-row multi-entry'>
                      <td colSpan={5}>{schedule.scheduleName}</td>
                      <td className='edit-delete-container'>
                        <div 
                          className="delete"
                          onClick={() => confirmDelete(schedule.scheduleId)}
                        >
                          Delete
                        </div>

                        <div 
                          className="duplicate"
                          onClick={() => handleDuplicate(schedule.scheduleId)}
                        >
                          Duplicate
                        </div>

                        <div 
                          className="edit"
                          onClick={() => handleEditModal(schedule.scheduleId)}
                        >
                        View/Edit
                        </div>
                      </td>
                    </tr>
                    {schedule.details.map((detail, index) => (
                      <tr 
                        className={detail.exception === true ? 'cal-adjustment multi-entry' : 'multi-entry'}
                        key={`schedule-item-${index}`}
                      >
                        <td></td>
                        <td>{detail.exception === true ? 
                          (<>
                            <div>
                              {detail.days}
                            </div>
                            <div>
                              {detail.dates}
                            </div>
                          </>)
                          : 
                          (detail.days)
                        }
                        </td>
                        <td>{detail.times}</td>
                        <td>{detail.temps}</td>
                        <td className='detail-mode'>
                          <div className={detail.mode.toLowerCase().replace(/[\s;]+/g, "-")}>
                            {detail.mode}
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    ))}
                  </Fragment>)
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="schedule-assignment-container main-containers">
          <div className="schedule-header">
            <div className="title-add-button">
              <h3>Schedule Assignment</h3>
            </div>
            
            <hr />
          </div>

          {processingAssignment && (<Loading message='Processing request' />)}

          <div className={processingAssignment ? 
          "processing schedule-assignment-table schedules-table"
          : 
          "schedule-assignment-table schedules-table"}
          >
            {scheduleAssignments && scheduleAssignments.map((building) => (
              <table key={building.buildingShortDescription}>
                <thead>
                    <tr>
                        <th colSpan={2}>{building.buildingLongDescription}</th>
                    </tr>
                </thead>
                
                <tbody>
                  {building.thermalZones.map((zone) => (
                    <tr className='zone-entry' key={zone.thermalZoneEntityKey}>
                        <td>{zone.thermalZoneLongDescription}</td>
                        <td>
                          <select
                            name=""
                            id=""
                            value={
                              (zone.scheduleName !== null || zone.scheduleName !== undefined) ? 
                              zone.scheduleName
                              : 
                              'No Schedule'
                            }
                            onChange={(e) => handleAssignment(e, zone.thermalZoneEntityKey)}
                          >
                            {currentScheduleData && currentScheduleData.map((schedule, index) => (
                              <option 
                                value={schedule.scheduleName}
                                key={`${schedule.scheduleId}-${index}`}
                              >
                                {schedule.scheduleName}
                              </option>
                            ))}
                          </select>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ))}
          </div>
        </div>

        {modal}
      </div>
    </section>
  </>)
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentCampusData: state.currentCampusData
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentCampusData: (updatedCampusData) => {
      dispatch(updateCurrentCampusData(updatedCampusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Schedules))