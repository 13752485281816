import { connect } from 'react-redux'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import exportingModule from 'highcharts/modules/exporting'

import HomepageZoneCards from './subsections/HomepageZoneCards'
import HomepageBuildingCards from './subsections/HomepageBuildingCards'
import HomepageZoneSettings from './subsections/HomepageZoneSettings'

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  updateCurrentCampusData
} from '../actions'

import AuthenticatedComponent from '../utilities/AuthenticatedComponent'
import WithRouter from '../utilities/WithRouter'

require('highcharts/modules/accessibility')(Highcharts)
require('highcharts/modules/draggable-points')(Highcharts)
require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)

exportingModule(Highcharts)

class Homepage extends AuthenticatedComponent { 
  constructor(props) {
    super(props)
    this.state = {
      currentScreenSize: window.innerWidth,
      currentBuildingZoneData: null,
      thermalZoneSummaryData: null,
      indicatorsData: null,
      currentGXIndex: null,
      showSchedules: false,
    }
    this.selectBuilding = this.selectBuilding.bind(this)
    this.onBuildingChange = this.onBuildingChange.bind(this)
    this.selectZone = this.selectZone.bind(this)
    this.getComfortData = this.getComfortData.bind(this)
    this.getBuildingZoneData = this.getBuildingZoneData.bind(this)
    this.getThermalZoneSummary = this.getThermalZoneSummary.bind(this)
    this.updateThermalZoneData = this.updateThermalZoneData.bind(this)
    this.zoneSummaryCallback = this.zoneSummaryCallback.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props

    if (prevProps.router.location.pathname !== router.location.pathname) {
      this.processPath()
    }
  }

  componentDidMount() {
    const { showMobile, currentCampusId, currentBuildingId, currentCampusData } = this.props
    
    if (showMobile) {
      this.getBuildingZoneData(currentBuildingId)
    } else {
      this.getComfortData(currentCampusId)
    }
  }

  processPath() {
    const {
      router,
      currentCampusId,
      currentBuildingId,
      setCurrentCampus,
      setCurrentBuilding,
      setCurrentZone,
      activeTab,
    } = this.props
    
    if (router.params) {
      //if on Comfort page
      if (activeTab === 'comfort') {
        //if new url has building 
        if (router.params.buildingId) {
          //if new url has different building, set building
          if (router.params.buildingId !== currentBuildingId) {
            setCurrentBuilding(router.params.buildingId)
          } 
        }

        //if new url has zoneId
        if (window.location.pathname.split('/')[4]) {
          setCurrentZone(window.location.pathname.split('/')[4])
        } else {
          setCurrentZone(null)
        }
      }
    }
  }

  successGetComfortData(comfortData) {
    this.props.updateCurrentCampusData(comfortData)
  }

  successGetBuildingZoneData(buildingZoneData) {
    this.setState({
      currentBuildingZoneData: buildingZoneData,
    })
  }

  zoneSummaryCallback(zoneSummaryData) {
    this.setState({
      thermalZoneSummaryData: zoneSummaryData
    })
  }

  onBuildingChange(buildingId, updatePathname = true) {
    this.selectBuilding(buildingId, updatePathname)
  }

  selectBuilding(buildingId, updatePathname = true) {
    const {
      currentCampusData,
      currentCampusId,
      currentBuildingId,
      setCurrentBuilding,
      setCurrentZone,
      showMobile
    } = this.props;

    setCurrentBuilding(buildingId)

    if (currentCampusData) {
      if (buildingId && buildingId !== currentBuildingId && buildingId !== null) {
        if (updatePathname) {
          if (showMobile) {
            this.navigateWithValidation('/home/' + currentCampusData.campusDescription.campusEntityKey + '/' + buildingId)
          } else {
            this.navigateWithValidation('/comfort/' + currentCampusData.campusDescription.campusEntityKey + '/' + buildingId)
          }
        }
          
      } else {
          if (!currentCampusId) {
            if (showMobile) {
              this.navigateWithValidation('/home/')
            } else {
              this.navigateWithValidation('/comfort/')
            }
            setCurrentZone(null)
            setCurrentGX(null)
          } else {
            if (showMobile) {
              this.navigateWithValidation('/home/' + currentCampusData.campusDescription.campusEntityKey)
            } else {
              this.navigateWithValidation('/comfort/' + currentCampusData.campusDescription.campusEntityKey)
            }
            setCurrentZone(null)
            setCurrentGX(null)
          }
      }
    }
  }

  selectZone(selectedZoneId) {
    const {
      currentCampusData,
      currentBuildingId,
      showMobile,
      setCurrentZone,
    } = this.props

    if (selectedZoneId) {
      setCurrentZone(selectedZoneId)
      setCurrentGX(null)
      this.setState({
        thermalZoneSummaryData: null,
      })
      if (currentBuildingId) {
        if (showMobile) {
          this.navigateWithValidation('/home/' + currentCampusData.campusDescription.campusEntityKey
          + '/' + currentBuildingId + '/' + selectedZoneId)   
        } else {
          this.navigateWithValidation('/comfort/' + currentCampusData.campusDescription.campusEntityKey
          + '/' + currentBuildingId + '/' + selectedZoneId)   
        }                  
      }

      this.getThermalZoneSummary(selectedZoneId)
    }
  }

  updateThermalZoneData(zoneId) {
    this.getThermalZoneSummary(zoneId)
  }

  navigateWithValidation(newPath) {
    if (this.props.router.location.pathname !== newPath) {
      this.props.router.navigate(newPath)
    }
  }

  render() {
    const {
      currentCampusData,
      currentCampusId,
      currentBuildingId,
      currentZoneId,
      updateCampusData,
      showMobile,
      activeTab,
      openModal,
      selectedModal,
      setSelectedModal,
      currentScheduleId,
      setCurrentScheduleId,
      activeFilter,
      setActiveFilter
    } = this.props

    const { 
      currentBuildingZoneData,
      thermalZoneSummaryData,
    } = this.state

    let section = null

    if (!showMobile) {
      if (activeTab === 'comfort') {
        if (Object.keys(currentCampusData).length > 0 &&
            currentCampusId && 
            currentBuildingId === null && 
            currentZoneId === null) 
          {
          section = <HomepageBuildingCards
            currentBuildingId={currentBuildingId}
            selectBuilding={this.selectBuilding}
            currentCampusData={currentCampusData}
            updateCampusData={updateCampusData}
            currentZoneId={currentZoneId}
            selectZone={this.selectZone}
            showMobile={showMobile}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        } else if (currentCampusData && 
            currentCampusData.buildings && 
            Object.keys(currentCampusData.buildings).length > 0) 
          {
          if (currentZoneId) {
            section = <HomepageZoneSettings
              thermalZoneSummaryData={thermalZoneSummaryData}
              updateThermalZoneData={this.updateThermalZoneData}
              currentCampusId={currentCampusId}
              currentZoneId={currentZoneId}
              currentCampusData={currentCampusData}
              currentBuildingId={currentBuildingId}
              currentBuildingZoneData={currentBuildingZoneData}
              getBuildingZoneData={this.getBuildingZoneData}
              showMobile={showMobile}
              openModal={openModal}
              selectedModal={selectedModal}
              setSelectedModal={setSelectedModal}
              currentScheduleId={currentScheduleId}
              setCurrentScheduleId={setCurrentScheduleId}
            />
          } else {
            section = <HomepageZoneCards
              currentCampusData={currentCampusData}
              currentBuildingZoneData={currentBuildingZoneData}
              currentBuildingId={currentBuildingId}
              selectZone={this.selectZone}
              currentZoneId={currentZoneId}
              getBuildingZoneData={this.getBuildingZoneData}
              showMobile={showMobile}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          }
        }
      }
    } else if (showMobile) {
      if (currentCampusData && 
        currentCampusData.buildings && 
        Object.keys(currentCampusData.buildings).length > 0) {
        if (currentZoneId) {
          section = <HomepageZoneSettings
            thermalZoneSummaryData={thermalZoneSummaryData}
            updateThermalZoneData={this.updateThermalZoneData}
            currentCampusId={currentCampusId}
            currentZoneId={currentZoneId}
            currentCampusData={currentCampusData}
            currentBuildingId={currentBuildingId}
            currentBuildingZoneData={currentBuildingZoneData}
            getBuildingZoneData={this.getBuildingZoneData}
            showMobile={showMobile}
            openModal={openModal}
            selectedModal={selectedModal}
            setSelectedModal={setSelectedModal}
            currentScheduleId={currentScheduleId}
            setCurrentScheduleId={setCurrentScheduleId}
          />
        } else {
          section = <HomepageZoneCards
            currentCampusData={currentCampusData}
            currentBuildingZoneData={currentBuildingZoneData}
            currentBuildingId={currentBuildingId}
            selectZone={this.selectZone}
            currentZoneId={currentZoneId}
            getBuildingZoneData={this.getBuildingZoneData}
            showMobile={showMobile}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        }
      }
    }

    return (
      <main className="homepage main-content">
        {Object.keys(currentCampusData).length > 0 && (<>
          {section}
        </>)}
      </main>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    campuses: state.campuses,
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    currentUserData: state.currentUserData
  }
}

// used by parent class AuthenticatedComponent
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Homepage))