import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import icon_chevron from '../../assets/icon_chevron.svg'
import icon_clock from '../../assets/icon_clock.svg'

const TimeSettings = styled.div`
    display: flex;
    flex-flow: column;
    height: auto;
    justify-content: space-between;
    max-height: 170px;
    width: 100%;

    label {
        font-weight: 500;
    }

    .time-input-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        position: relative;

        span {
            color: #324E55;
            font-size: 16px;
            font-weight: 500;
            left: 42px;
            line-height: 24px;
            pointer-events: none;
            position: absolute;
            top: 11px;
        }

        select {
            background: url(${icon_clock}) 15px center no-repeat;
            background-size: 17px 17px;
            border: 2px solid #EAECF0;
            box-shadow: none;
            color: #324E55;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding: 10px 55px 10px 70px;
            text-align: center;
            width: 100%;
            z-index: 2;
        }

        img {
            filter: opacity(0.5);
            position: absolute;
            right: 8px;
            top: 15px;
            z-index: 1;
        }
    }
`

const StartEndTime = ({
    handleChange,
    index,
    selectedFromHour,
    selectedFromMin,
    selectedToHour,
    selectedToMin
}) => {
    const [timeToFilter, setTimeToFilter] = useState(selectedFromHour ? `${selectedFromHour}:${selectedFromMin}` : '06:00')
    const currentTimezone = moment().tz(moment.tz.guess()).format('z')
    let convertedFromHour
    let convertedFromMin
    let convertedToHour
    let convertedToMin
    let convertedFromTime
    let convertedToTime
    let timeOptions = []
    let endTimeOptions = []

    //if schedule editor, format time
    if (selectedFromHour) {
        if (selectedFromHour < 9 || selectedToHour < 9) {
            convertedFromHour = selectedFromHour.toString().padStart(2, '0')
            convertedToHour = selectedToHour.toString().padStart(2, '0')
        } else {
            convertedFromHour = selectedFromHour.toString()
            convertedToHour = selectedToHour.toString()
        }

        if (selectedFromMin === 0 || selectedToMin === 0) {
            convertedFromMin = selectedFromMin.toString().padStart(2, '0')
            convertedToMin = selectedToMin.toString().padStart(2, '0')
        } else {
            convertedFromMin = selectedFromMin.toString()
            convertedToMin = selectedToMin.toString()
        }

        convertedFromTime = `${convertedFromHour}:${convertedFromMin}`
        convertedToTime = `${convertedToHour}:${convertedToMin}`
    }

    for (let i=0; i < 25; i++) {
        for (let j=0; j < 4; j++) {
        timeOptions.push(`${i < 10 ? `0${i}` : i}:${j === 0 ? `00` : 15*j}`)
        }
    }

    timeOptions = timeOptions.slice(1, -3)

    

    const handleTime = (e, type) => {
        handleChange(index, type, e.target.value)

        if (type === 'startTime') {
            setTimeToFilter(e.target.value)
        }
    }

    timeOptions.map((time) => {
        if (time > timeToFilter) {
            endTimeOptions.push(time)
        }
    })

    return (
        <TimeSettings className="time-container">
            <div className="start-time-container">
                <label>
                Start Time
                <div className="time-input-container">
                    {/* <span>{currentTimezone}</span> */}
                    <select
                    name="startTime"
                    id="start-time"
                    defaultValue={convertedFromTime ? 
                        convertedFromTime
                        :
                        '06:00'
                    }
                    onChange={(e) => handleTime(e, 'startTime')}
                    >
                    {timeOptions.map(option => (
                        <option key={`${option}-start-time`} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                    <img src={icon_chevron} alt="icon to show time options" height="16" width="16" />
                </div>
                </label>
            </div>
            <div className="end-time-container">
                <label>
                End Time
                <div className="time-input-container">
                    {/* <span>{currentTimezone}</span> */}
                    <select
                    name="endTime"
                    id="end-time"
                    defaultValue={convertedToTime ? 
                        convertedToTime
                        :
                        '14:30'
                    }
                    onChange={(e) => handleTime(e, 'endTime')}
                    >
                    {endTimeOptions.map(option => (
                        <option key={`${option}-end-time`} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
                    <img src={icon_chevron} alt="icon to show time options" height="16" width="16" />
                </div>
                </label>
            </div>
        </TimeSettings>
    )
}

export default StartEndTime