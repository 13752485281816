import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  setCurrentCampus,
  setCurrentBuilding,
  setCurrentZone,
  setCurrentGX,
  updateCurrentCampusData
} from '../actions'
import DesktopNav from './DesktopNav'
import { Chevron } from '../assets/Chevron'
import WithRouter from '../utilities/WithRouter'
import CurrentWeather from './CurrentWeather'

const PageHeader = ({
  currentUrl,
  currentCampusData,
  currentCampusId,
  currentBuildingId,
  currentZoneId,
  currentGXId,
  hasSingleGX,
  activeTab,
  onTabChange,
  onZoneChange,
  showMobile,
  onBuildingChange,
  onGXChange,
  router
}) => {
  const [currentBuildingName, setCurrentBuildingName] = useState()
  const zoneParam = window.location.pathname.split('/')[4]
  const pageUrl = window.location.pathname.split('/')[1]
  const showHeader = (pageUrl !== 'settings' && pageUrl !== 'resources')

  const navigateWithValidation = (newPath) => {
    if (router.location.pathname !== newPath) {
        router.navigate(newPath)
    }
  }

  useEffect(() => {
    if (currentCampusData && currentCampusData.buildings && showMobile) {
      currentCampusData.buildings.filter((building) => {
        if (building.buildingDescription.buildingEntityKey === currentBuildingId) {
          setCurrentBuildingName(building.buildingDescription.longBuildingDisplayName)
          
          if (zoneParam === undefined || zoneParam === null) {
            onZoneChange(null)
          }          
        }
      })
    }
  }, [currentCampusData, onBuildingChange])

  const handleRemoveZone = () => {
    onZoneChange(null)

    navigateWithValidation('/home/' + currentCampusId + '/' + currentBuildingId)
  }

  return (<>
    {showHeader && (
      <header className={showMobile ? 'page-header' : 'page-header desktop'}>
        <div className="header-container">
          <div className={!showMobile ? "main-navigation desktop" : "main-navigation"}>
            {showMobile ? (
              currentZoneId ? 
                (<>
                  <div className="building-nav" onClick={handleRemoveZone}>
                    <Chevron />
                    <span>{currentBuildingName}</span>
                  </div>
                </>)
                :
                (currentBuildingName)
            )
            : (
              <DesktopNav
                currentCampusData={currentCampusData}
                currentCampusId={currentCampusId}
                currentBuildingId={currentBuildingId}
                currentZoneId={currentZoneId}
                currentGXId={currentGXId}
                hasSingleGX={hasSingleGX}
                onBuildingChange={onBuildingChange}
                onGXChange={onGXChange}
                onZoneChange={onZoneChange}
                activeTab={activeTab}
                onTabChange={onTabChange}
                router={router}
              />
            )
            }

            {showMobile && currentCampusData?.weather && <CurrentWeather currentCampusData={currentCampusData} />}
          </div>
        </div>

        <hr className={!showMobile ? 'hidden' : undefined} />
      </header>
    )}
  </>)
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentCampus: (campusId) => {
      dispatch(setCurrentCampus(campusId))
    },
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    },
    updateCurrentCampusData: (campusData) => {
      dispatch(updateCurrentCampusData(campusData))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(PageHeader))