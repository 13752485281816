import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import gxIcon from '../assets/kiosk/gxMeter.svg'
import solarIcon from '../assets/kiosk/solar.svg'
import batteryIcon0 from '../assets/kiosk/battery0.svg'
import batteryIcon25 from '../assets/kiosk/battery25.svg'
import batteryIcon50 from '../assets/kiosk/battery50.svg'
import batteryIcon75 from '../assets/kiosk/battery75.svg'
import batteryIcon100 from '../assets/kiosk/battery100.svg'
import evWithoutCar from '../assets/kiosk/evWithoutCar.svg'
import evWithCar from '../assets/kiosk/evWithCar.svg'
import hvacIcon from '../assets/kiosk/hvac.svg'
import otherIcon from '../assets/kiosk/building.svg'
import arrow from '../assets/kiosk/arrow.svg'

const KioskModelDiv = styled.div`
    align-items: center;
    border-left: 1px solid #C2D0D1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-width: 1200px;
    padding-left: 3vw;
    width: 60%;

    hr {
        background: #2FE0E6;
        background-size: 400% 400%;
        border: none;
        border-radius: 50px 50px 0 0;
        height: 2px;
        margin: 0;
        width: calc(100% - 92px);
    }

    .bottom-container {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .block {
            align-items: center;
            background: white;
            border: 2px solid transparent;
            border-radius: 5px;
            box-shadow: 0px 0px 10px rgba(194, 208, 209, 0.5);
            display: flex;
            flex-direction: column;
            height: 75px;
            justify-content: center;
            width: 90px;

            &.active {
                border: 2px solid #2FE0E6;
            }
    
            p {
                color: #324E55;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
                &.label {
                    font-weight: 600;
                    margin: 6px 0 0;
                }
            }
        }

        .arrow {
            &.pointUp {
                transform: rotate(180deg);
            }

            &.inactive {
                filter: opacity(0.25) grayscale(1);

                .arrow-container {
                    .line {
                        border: 1px dashed black;
                    }
                    .arrow-icon {
                        border-color: black;
                    }
                }
            }

            .arrow-container {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                margin: 0 0 2px;
                position: relative;

                .arrow-icon {
                    border: solid #2FE0E6;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 3px;
                    transform: rotate(45deg);
                }

                .line {
                    border: solid #2FE0E6;
                    border-width: 1px;
                    height: 14px;
                    margin-bottom: -8px;
                    position: sticky;
                }
            }
        }
    }

    img {
        &.icon {
            width: 25px;
        }
    }

    @media screen and (max-width: 1024px) {
        .container .block {
            height: 55px;
            width: 55px;

            p {
                display: none;
            }
        }

        hr {
            width: calc(100% - 52px);
        }
    }
`

const KioskModel = ({
    gxPower,
    hasSolar,
    solarPower,
    hasBattery,
    batterySoc,
    batteryDisplaySoc,
    batteryPower,
    hasEv,
    hasEvConnected,
    evPower,
    hvacPower,
    otherPower
}) => {
    const [gxArrowClass, setGxArrowClass] = useState('arrow')
    const [solarArrowClass, setSolarArrowClass] = useState('arrow')
    const [batteryArrowClass, setBatteryArrowClass] = useState('arrow')

    let batteryIcon

    switch (batteryDisplaySoc) {
        case 0:
            batteryIcon = batteryIcon0
            break;
        case 25:
            batteryIcon = batteryIcon25
            break;
        case 50:
            batteryIcon = batteryIcon50
            break;
        case 75:
            batteryIcon = batteryIcon75
            break;
        case 100:
            batteryIcon = batteryIcon100
            break;
        default:
            batteryIcon = batteryIcon0
            break;
    }

    useEffect(() => {
        if (gxPower === 0) {
            setGxArrowClass('arrow inactive')
        } else if (gxPower > 0) {
            setGxArrowClass('arrow')
        } else if (gxPower < 0) {
            setGxArrowClass('arrow pointUp')
        }
    }, [gxPower])

    useEffect(() => {
        if (solarPower === 0) {
            setSolarArrowClass('arrow inactive')
        } else if (solarPower > 0) {
            setSolarArrowClass('arrow')
        } else if (solarPower < 0) {
            setSolarArrowClass('arrow pointUp')
        }
    }, [solarPower])

    useEffect(() => {
        if (batteryPower === 0) {
            setBatteryArrowClass('arrow inactive')
        } else if (batteryPower > 0) {
            setBatteryArrowClass('arrow pointUp')
        } else if (batteryPower < 0) {
            setBatteryArrowClass('arrow')
        }
    }, [batteryPower])
    
    return (
        <KioskModelDiv className='kiosk-container'>
                <div className="top-container">
                    <div className="gx container">
                        <div className={gxPower > 0 ? 'gx block active' : 'gx block'}>
                            <img className="icon" src={gxIcon} alt="Grid Meter Icon" />
                            <p className="label">Grid</p>
                            <p>{gxPower} kW</p>
                        </div>

                        <div className={gxArrowClass}>
                            <div className="arrow-container">
                                <div className="line"></div>
                                <i className="arrow-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="bottom-container">
                    {hasSolar && (
                        <div className="solar container">
                            <div className={solarArrowClass}>
                                <div className="arrow-container">
                                    <div className="line"></div>
                                    <i className="arrow-icon"></i>
                                </div>
                            </div>
                            <div className={solarPower > 0 ? 'solar block active' : 'solar block'}>
                                <img className="icon" src={solarIcon} alt="Solar Icon" />
                                <p className="label">Solar</p>
                                <p>{solarPower} kW</p>
                            </div>
                        </div>
                    )}
                    

                    {hasBattery && (
                        <div className="battery container">
                            <div className={batteryArrowClass}>
                                <div className="arrow-container">
                                    <div className="line"></div>
                                    <i className="arrow-icon"></i>
                                </div>
                            </div>                
                            
                            <div className={batterySoc < 0 ? 'battery block active' : 'battery block'}>
                                <img className="icon" src={batteryIcon} alt="Battery Icon" />
                                <p className="label">Battery</p>
                                <p>{batterySoc} %</p>
                            </div>
                        </div> 
                    )}
                                

                    {hasEv && (
                        <div className="ev container">
                            <div className={evPower > 0 ? 'arrow' : 'arrow inactive'}>
                                <div className="arrow-container">
                                    <div className="line"></div>
                                    <i className="arrow-icon"></i>
                                </div>
                            </div>                
                            
                            <div className={evPower > 0 ? 'ev block active' : 'ev block'}>
                                <img className="icon" src={hasEvConnected ? evWithCar : evWithoutCar} alt="EV Charger Icon" />
                                <p className="label">EV Charger</p>
                                <p>{evPower} kW</p>
                            </div>
                        </div>
                    )}
                

                    <div className="hvac container">
                        <div className={hvacPower !== 0 && hvacPower !== null ? 'arrow' : 'arrow inactive'}>
                            <div className="arrow-container">
                                <div className="line"></div>
                                <i className="arrow-icon"></i>
                            </div>
                        </div>                
                        
                        <div className={hvacPower ? 'hvac block active' : 'hvac block'}>
                            <img className="icon" src={hvacIcon} alt="HVAC Icon" />
                            <p className="label">HVAC</p>
                            <p>{hvacPower} kW</p>
                        </div>
                    </div>


                    <div className="other container">
                        <div className={otherPower !== 0 && otherPower !== null ? 'arrow' : 'arrow inactive'}>
                            <div className="arrow-container">
                                <div className="line"></div>
                                <i className="arrow-icon"></i>
                            </div>
                        </div>                
                        
                        <div className={otherPower ? 'other block active' : 'other block'}>
                            <img className="icon" src={otherIcon} alt="Building/Other Icon" />
                            <p className="label">Other</p>
                            <p>{otherPower} kW</p>
                        </div>
                    </div>
                </div>
        </KioskModelDiv>
    )
}

export default KioskModel