import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import icon_chevron from '../assets/icon_chevron.svg'
import { connect } from 'react-redux'
import WithRouter from '../utilities/WithRouter'
import { NavLink } from 'react-router-dom'
import { elexity } from '../utilities/AdvancedGraphHelper'
import { setCurrentBuilding, setCurrentZone, setCurrentGX } from '../actions'


const Tab = styled.button`
  background: white;
  border-width: 0 0 5px 0;
  border-color: #EAECF0;
  border-style: solid;
  color: #1D2939;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  
  opacity: 0.6;
  outline: 0;
  

  &.nav-button {
    min-width: 150px;
    width: 100%;
  }

  &.nav-width {
    cursor: unset;
    width: 10%;
  }
  
  ${({ active }) =>
    active &&
    `
    border-bottom: 5px solid #15B79E;
    opacity: 1;
    pointer-events: none;
  `}
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
`

const types = ['Comfort', 'Energy', 'Schedules', 'Events']

const DesktopNav = ({ 
  currentCampusData,
  currentCampusId,
  currentBuildingId,
  currentZoneId,
  currentGXId,
  hasSingleGX,
  activeTab,
  onTabChange,
  onBuildingChange,
  onGXChange,
  onZoneChange,
  router
}) => {
  const [active, setActive] = useState(activeTab)
  const [currentBuildingName, setCurrentBuildingName] = useState('')

  const showBuildingNav = currentCampusData && currentBuildingId
  const showGXNav = currentCampusData && !currentBuildingId && currentGXId

  useEffect(() => {
    //set building when campus data loads
    if (currentCampusData && currentCampusData.buildings) {
      if (currentZoneId) {
        currentCampusData && currentCampusData.buildings.filter((building) => {
          let tempName
          if (building.buildingDescription.buildingEntityKey === currentBuildingId) {
            tempName = building.buildingDescription.shortBuildingDisplayName
            tempName = tempName.split(' - ').pop()
            setCurrentBuildingName(tempName)
          }
        })
      }
    }
  }, [currentCampusData])

  useEffect(() => {
    //set building name when zone changes
    if (currentCampusData && currentCampusData.buildings) {
      if (currentZoneId) {
        currentCampusData && currentCampusData.buildings.filter((building) => {
          let tempName
          if (building.buildingDescription.buildingEntityKey === currentBuildingId) {
            tempName = building.buildingDescription.shortBuildingDisplayName
            tempName = tempName.split(' - ').pop()
            setCurrentBuildingName(tempName)
          }
        })
      }
    }
  }, [currentZoneId])

  const navigateWithValidation = (newPath) => {
    if (router.location.pathname !== newPath) {
        router.navigate(newPath)
    }
  }

  const handleTabChange = (type) => {
    if (activeTab !== type.toLowerCase()) {
      onBuildingChange(null)
      onZoneChange(null)
      onGXChange(null)
      onTabChange(type.toLowerCase())
      setActive(type.toLowerCase())

      if (activeTab === 'comfort' || activeTab === 'energy') {
        elexity.admin.monitor.graphs.halt()
      }
    }
  }

  const handleSubNavCampus = () => {
    if (currentBuildingId) {
      onBuildingChange(null)
    } else if (currentGXId) {
      onGXChange(null)
    }

    elexity.admin.monitor.graphs.halt()

    navigateWithValidation(`${activeTab}/` + currentCampusId)
  }

  const handleSubNavBuilding = () => {
    elexity.admin.monitor.graphs.halt()
    onZoneChange(null)

    navigateWithValidation(`${activeTab}/` + currentCampusId + '/' + currentBuildingId)
  }

  return (
    <>
      <ButtonGroup className='desktop-nav'>
        {types.map(type => (
          <Tab
            key={type.toLowerCase()}
            active={active === type.toLowerCase()}
            className='nav-button'
          >
            <NavLink
            to={`/${type.toLowerCase()}/${currentCampusId}`}
            onClick={() => handleTabChange(type.toLowerCase())}>
            {type}
            </NavLink>
            
          </Tab>
        ))}
        <Tab className='nav-width'></Tab>
      </ButtonGroup>

      {(showBuildingNav || showGXNav) && 
        !hasSingleGX &&
        currentCampusData?.campusDescription &&
       (
        <nav className='desktop-subnav'>
          <div 
            className="campus-name"
            onClick={() => handleSubNavCampus()}
          >
            <img src={icon_chevron} alt="indicator arrow to go back to campus" width={15} />
            {currentCampusData.campusDescription.longCampusDisplayName}
          </div>

          {currentZoneId && currentBuildingId && (
            <div 
              className="building-name"
              onClick={() => handleSubNavBuilding()}
            >
              <img src={icon_chevron} alt="indicator arrow to go back to building" width={15} />
              {currentBuildingName}
            </div>
          )}
        </nav>
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    currentCampusId: state.currentCampusId,
    currentBuildingId: state.currentBuildingId,
    currentZoneId: state.currentZoneId,
    currentGXId: state.currentGXId,
    currentCampusData: state.currentCampusData,
    campuses: state.campuses
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentBuilding: (buildingId) => {
      dispatch(setCurrentBuilding(buildingId))
    },
    setCurrentZone: (zoneId) => {
      dispatch(setCurrentZone(zoneId))
    },
    setCurrentGX: (gxId) => {
      dispatch(setCurrentGX(gxId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(DesktopNav))