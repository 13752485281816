export const Chevron = () => {
    return(
        <svg 
            width="25" 
            height="24" 
            viewBox="0 0 25 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className="chevron"
        >
        <path 
            d="M9.5 18L15.5 12L9.5 6" 
            stroke="black" 
            strokeWidth="2" 
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        </svg>
    )
}